// SPヘッダードロワーボタン
$(function () {
  $('.js-drawer_btn').click(function () {
    $(this).toggleClass('active');
    if ($(this).hasClass('active')) {
      $('.l-drawer_logo_img, .l-drawer_btn').css('position', 'fixed');
      $('html, body').css('overflow', 'hidden');
    } else {
      $('.l-drawer_logo_img').css('position', 'static');
      $('.l-drawer_btn').css('position', 'absolute');
      $('html, body').removeAttr('style');
    }
    $('.l-drawer_bg').fadeToggle();
    $('nav').toggleClass('open');
  })
  $('.l-drawer_bg').click(function () {
    $(this).fadeOut();
    $('.js-drawer_btn').removeClass('active');
    $('.l-drawer_logo_img').css('position', 'static');
    $('.l-drawer_btn').css('position', 'absolute');
    $('html, body').removeAttr('style');
    $('nav').removeClass('open');
  });
})

// ヘッダーのユーザーメニューボタン
$(document).on('click', '#user_menu_down', function () {
  $('.user_menu_arrow').removeClass('fa-angle-down');
  $('.user_menu_arrow').addClass('fa-angle-up');
  $('.user_list').fadeIn(150);
  $(this).attr('id', 'user_menu_up');
});
$(document).on('click', '#user_menu_up', function () {
  $('.user_menu_arrow').removeClass('fa-angle-up');
  $('.user_menu_arrow').addClass('fa-angle-down');
  $('.user_list').fadeOut(150);
  $(this).attr('id', 'user_menu_down');
});

// トップへ戻るボタン
$(window).scroll(function () {
  if ($(this).scrollTop() > 100) {
    $('.c-backtotop').fadeIn();
  } else {
    $('.c-backtotop').fadeOut();
  }
});
$(function () {
  $('.c-backtotop').click(function () {
    $('body, html').animate({ scrollTop: 0 }, 800);
  });
});

// フラッシュメッセージの表示を3秒で消す
$(function () {
  setTimeout("$('.js-alert').fadeOut()", 3000);
});
