require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")

import "@fortawesome/fontawesome-free/js/all"
import "../stylesheets/front/application.scss"
import "../javascripts/front/common.js"
require("trix")
require("@rails/actiontext")
